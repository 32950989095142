// import React from 'react';
// import { Link } from 'react-router-dom';

// function CardItem(props) {
//   return (
//     <>
//       <li className='cards__item'>
//         <Link className='cards__item__link' to={props.path}>
//           <figure className='cards__item__pic-wrap' data-category={props.label}>
//             <img
//               className='cards__item__img'
//               alt='Travel Image'
//               src={props.src}
//             />
//           </figure>
//           <div className='cards__item__info'>
//             <h5 className='cards__item__text'>{props.text}</h5>
//           </div>
//         </Link>
//       </li>
//     </>
//   );
// }

// export default CardItem;


import React from 'react';
import { Link } from 'react-router-dom';

function CardItem(props) {
  const handleClick = () => {
    // Set the values in localStorage
    localStorage.setItem('moduleName', props.moduleName);
    localStorage.setItem('moduleTestName', props.moduleTestName);

    // Additional actions if necessary
    if (props.onClick) {
      props.onClick(); // Call any additional onClick handler passed in props
    }
  };

  return (
    <>
      <li className='cards__item' onClick={handleClick}>
        <Link className='cards__item__link' to={props.path}>
          <figure className='cards__item__pic-wrap' data-category={props.label}>
            <img
              className='cards__item__img'
              alt='Travel Image'
              src={props.src}
            />
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text}</h5>
          </div>
        </Link>
      </li>
    </>
  );
}

export default CardItem;
