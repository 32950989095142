import styles from "./test-option.module.css";

const TestOption1 = () => {
  return (
    <div className={styles.testOption}>
      <div className={styles.divpageContainer}>
        <div className={styles.divcSectionContent}>
          <div className={styles.heading1margin}>
            <div className={styles.heading1}>
              <b className={styles.takeAFreeContainer}>
                <p className={styles.takeAFree}>Take a free</p>
                <p className={styles.takeAFree}>mental health test</p>
              </b>
            </div>
          </div>
          <div className={styles.divcSubheadermargin}>
            <div className={styles.divcSubheader}>
              <div className={styles.ourShortOnlineContainer}>
                <p className={styles.takeAFree}>
                  Our short online mental health screening
                </p>
                <p className={styles.takeAFree}>
                  tests will help you determine if you should
                </p>
                <p className={styles.takeAFree}>
                  seek help from a licensed mental health
                </p>
                <p className={styles.takeAFree}>
                  professional to address mental health
                </p>
                <p className={styles.takeAFree}>issues.</p>
              </div>
            </div>
          </div>
          <div className={styles.divnavbar}>
            <div className={styles.linkmargin1}>
              <div className={styles.link1}>
                <b className={styles.borderlinePersonalityDisordeContainer}>
                  Anxiety
                </b>
              </div>
            </div>
            <div className={styles.linkmargin1}>
              <div className={styles.link2}>
                <b className={styles.borderlinePersonalityDisordeContainer}>
                  Depression
                </b>
              </div>
            </div>
            <div className={styles.linkmargin3}>
              <div className={styles.link3}>
                <b className={styles.borderlinePersonalityDisordeContainer}>
                  Bipolar Disorder
                </b>
              </div>
            </div>
            <div className={styles.linkmargin3}>
              <div className={styles.link4}>
                <b className={styles.borderlinePersonalityDisordeContainer}>
                  OCD
                </b>
              </div>
            </div>
            <div className={styles.linkmargin5}>
              <div className={styles.link5}>
                <b className={styles.borderlinePersonalityDisordeContainer}>
                  PTSD
                </b>
              </div>
            </div>
            <div className={styles.linkmargin1}>
              <div className={styles.link6}>
                <b className={styles.borderlinePersonalityDisordeContainer}>
                  Social Anxiety
                </b>
              </div>
            </div>
            <div className={styles.linkmargin5}>
              <div className={styles.link7}>
                <b className={styles.borderlinePersonalityDisordeContainer}>
                  Panic Disorder
                </b>
              </div>
            </div>
            <div className={styles.linkmargin5}>
              <div className={styles.link8}>
                <div className={styles.heading27}>
                  <b className={styles.borderlinePersonalityDisordeContainer}>
                    <p className={styles.takeAFree}>Borderline</p>
                    <p className={styles.takeAFree}>Personality Disorder</p>
                  </b>
                </div>
              </div>
            </div>
            <div className={styles.linkmargin3}>
              <div className={styles.link9}>
                <b className={styles.borderlinePersonalityDisordeContainer}>
                  Insomnia
                </b>
              </div>
            </div>
            <div className={styles.linkmargin5}>
              <div className={styles.link10}>
                <div className={styles.heading29}>
                  <b className={styles.borderlinePersonalityDisordeContainer}>
                    <p className={styles.takeAFree}>Postpartum</p>
                    <p className={styles.takeAFree}>Depression</p>
                  </b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestOption1;
