export const ADD_TO_CART="ADD_TO_CART"
export const REMOVE_FROM_CART="REMOVE_FROM_CART"
export const EMPTY_CART="EMPTY_CART"
export const PRODUCT_LIST="PRODUCT_LIST"
export const SET_PRODUCT_LIST="SET_PRODUCT_LIST"
export const SEARCH_PRODUCT="SEARCH_PRODUCT"
export const ADD_Q="ADD_Q"
export const ADD_R="ADD_R"
export const ADD_RC="ADD_RC"
export const RESET_DATA = 'RESET_DATA';

//nOTHING BUT JUST DEFINING EVERY ACTION TYPE




